module.exports = {
  "analyserHeight": "144px",
  "borderRadius": "4px",
  "colorBlack": "#171414",
  "colorBlood": "#675656",
  "colorDarkGray": "#3a3a3a",
  "colorGray": "#868686",
  "colorLightGray": "#ececec",
  "colorSmoke": "#f9f9f9",
  "colorWhite": "#fff",
  "fontFamily": "\"Lato\", \"Helvetica Neue\", \"Helvetica\", sans-serif",
  "fontSize": "15px",
  "fontSizeSmall": "13px",
  "iconSize": "24px",
  "lineHeight": "23px",
  "spacing": "13px"
};
